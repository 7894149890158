@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
/*==================== GOOGLE FONTS ====================*/

/*==================== VARIABLES CSS ====================*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color */
  --hue-color: 250; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

  /* HSL color mode */
  --first-color: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 61%);
  --first-color-alt: hsl(var(--hue-color), 57%, 53%);
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 96%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;

  /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margins Bottom ==========*/
  /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/*========== MINE ==========*/

.dot {
  height: 5px;
  width: 5px;
  vertical-align: middle;
  background-color: hsl(250, 8%, 15%);
  background-color: var(--title-color);
  border-radius: 50%;
  display: inline-block;
}

.footer__dot {
  height: 4px;
  width: 4px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

/* span {
  color: var(--text-color);
  font-family: "Zen Dots", cursive;
  margin: 4px;
} */

.blinker {
  opacity: 1;
  border-left: 10px solid hsl(250, 69%, 61%);
  border-left: 10px solid var(--first-color);
  -webkit-animation: blinker 1.2s steps(2, start) infinite;
          animation: blinker 1.2s steps(2, start) infinite;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.typeing {
  font-size: 0.8em;
  font-weight: bold;
}

@-webkit-keyframes blinker {
  to {
    visibility: hidden;
  }
}

@keyframes blinker {
  to {
    visibility: hidden;
  }
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 2.875rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    /* --normal-font-size: 0.9rem; */
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*========== Variables Dark theme ==========*/

body.dark-theme {
  /* HSL color mode */

  --first-color-second: hsl(var(--hue-color), 30%, 8%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 12%);
  --container-color: hsl(var(--hue-color), 29%, 16%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}

/*========== Button Dark/Light ==========*/

.nav__btns {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.change-theme {
  font: size 1.25rem;
  color: hsl(250, 8%, 15%);
  color: var(--title-color);
  margin-right: 1rem;
  margin-right: var(--mb-1);
  margin-bottom: 0.4rem;
  cursor: pointer;
}

.change-theme:hover {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

/*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
} */

body {
  margin: 0 0 3rem 0;
  margin: 0 0 var(--header-height) 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  background-color: hsl(250, 60%, 99%);
  background-color: var(--body-color);
  color: hsl(250, 8%, 45%);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: hsl(250, 8%, 15%);
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/
.section {
  padding: 2rem 0 4rem;
}

.section__title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
}

.section__subtitle {
  display: block;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  margin-bottom: 3rem;
  margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 768px;
  margin-left: 1.5rem;
  margin-left: var(--mb-1-5);
  margin-right: 1.5rem;
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  grid-gap: 1.4rem;
  gap: 1.4rem;
}

.uil {
  margin-bottom: -2px;
  margin-right: 2px;
  height: 16px;
  width: 16px;
}

.uil__portfolio {
  margin-top: 0px !important;
  margin-right: 6px !important;

  width: 22px !important;
  height: 22px !important;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  background-color: hsl(250, 60%, 99%);
  background-color: var(--body-color);
}

/*==================== NAV ====================*/
.nav {
  max-width: 968px;
  height: 3rem;
  height: var(--header-height);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.nav__logo,
.nav__toggle {
  color: hsl(250, 8%, 15%);
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
}
.nav__logo:hover {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}
.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
}
.nav__toggle:hover {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: hsl(250, 60%, 99%);
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
}

.nav__link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(250, 8%, 15%);
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.nav__link:hover {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

.nav__icon {
  height: 20px;
  width: 20px;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  cursor: pointer;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

.nav__close:hover {
  color: hsl(250, 57%, 53%);
  color: var(--first-color-alt);
}

/* show menu */
.show-menu {
  bottom: 0;
}

/* Active link */
.active-link {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}
/*==================== HOME ====================*/
.home__container {
  grid-gap: 1rem;
  gap: 1rem;
}
.home__content {
  grid-template-columns: 0.5fr 3fr;
  padding-top: 3.5rem;
  -webkit-align-items: center;
          align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.home__social-icon {
  /* font-size: 1.25rem; */
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

.home__social-icon:hover {
  /* font-size: 1.25rem; */
  color: hsl(250, 57%, 53%);
  color: var(--first-color-alt);
}

.home__blob {
  width: 200px;
  fill: hsl(250, 69%, 61%);
  fill: var(--first-color);
}

.home__blob-img {
  width: 170px;
}

.home__data {
  grid-column: 1/3;
}

.home__title {
  font-size: 2rem;
  font-size: var(--big-font-size);
}

.home__subtitle {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  color: hsl(250, 8%, 45%);
  color: var(--text-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-bottom: 0.75rem;
  margin-bottom: var(--mb-0-75);
}

.home__description {
  margin-bottom: 2rem;
  margin-bottom: var(--mb-2);
}

.home__scroll {
  /* display: none; */
}

.home__scroll-button {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
  transition: 0.3s;
}

.home__scroll-button:hover {
  -webkit-transform: translateY(0.25rem);
          transform: translateY(0.25rem);
}

.home__scroll-mouse {
  font-size: 2rem;
}

.home__scroll-name {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(250, 8%, 15%);
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-right: 0.25rem;
  margin-right: var(--mb-0-25);
}
.home__scroll-arrow {
  font-size: 1.25rem;
}

.video__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 1rem;
  z-index: 1000;
  z-index: var(--z-modal);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.video__modal-content {
  position: relative;
  background-color: #fff;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.video__modal-close {
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;

  font-size: 1.5rem;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
  cursor: pointer;
}

/* Active Modal */

.active-modal {
  opacity: 1;
  visibility: visible;
}

/*==================== BUTTONS ====================*/
button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease, -webkit-transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background: #0053ba;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  -webkit-transform: scale(0.99);
          transform: scale(0.99);
}

.button {
  display: inline-block;
  background-color: hsl(250, 69%, 61%);
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: hsl(250, 57%, 53%);
  background-color: var(--first-color-alt);
}

.button__icon {
  font-size: 1.25rem;
  margin-left: 0.5rem;
  margin-left: var(--mb-0-5);
  transition: 0.3s;
}
.button--flex {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
}

.button--small {
  padding: 0.75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}
/* link_button:hover {
  cursor: pointer;
} */
.button--link:hover {
  background-color: transparent;
  color: hsl(250, 57%, 53%);
  color: var(--first-color-alt);
}
/*==================== ABOUT ====================*/
.about__img {
  width: 320px;
  border-radius: 0.5rem;
  justify-self: center;
  -webkit-align-self: top;
          align-self: top;
}
.about__description {
  text-align: center;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}

.about__info {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}
.about__info-title {
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  color: hsl(250, 8%, 15%);
  color: var(--title-color);
}
.about__info.name {
  font-size: 0.75rem;
  font-size: var(--smaller-font-size);
}

.about__info-title,
.about__info-name {
  display: block;
  text-align: center;
}

.about__button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
/*==================== SKILLS ====================*/

.skills__container {
  grid-row-gap: 100;
  row-gap: 100;
}

.skills__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
  cursor: pointer;
}

.skills__icon,
.skills__arrow {
  font-size: 2rem;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

.skills__icon {
  margin-right: 0.75rem;
  margin-right: var(--mb-0-75);
}

.skills__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
}

.skills__subtitle {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(250, 8%, 65%);
  color: var(--text-color-light);
}

.skills__arrow {
  margin-left: auto;
  transition: 0.4s;
}

.skills__list {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
  padding-left: 2.7rem;
}

.skills__titles {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-0-5);
}

.skills__name {
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.skills__bar,
.skills__percentage {
  height: 5px;
  border-radius: 0.25rem;
}

.skills__bar {
  background-color: hsl(250, 92%, 85%);
  background-color: var(--first-color-lighter);
}

.skills__percentage {
  display: block;
  background-color: hsl(250, 69%, 61%);
  background-color: var(--first-color);
}

.skills__html {
  width: 90%;
}

.skills__css {
  width: 80%;
}

.skills__js {
  width: 70%;
}

.skills__react {
  width: 85%;
}

.skills__close .skills__list {
  height: 0;
  overflow: hidden;
}

.skills__open .skills__list {
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}

.skills__open .skills__arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
/*==================== QUALIFICATION ====================*/
.qualifications__tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-2);
}

.qualifications__button {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  cursor: pointer;
}

.qualifications__button:hover {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

.qualifications__icon {
  font-size: 1.8rem;
  margin-right: 0.25rem;
  margin-right: var(--mb-0-25);
}

.qualifications__data {
  display: grid;
  grid-template-columns: 1fr -webkit-max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.qualifications__data-left {
  text-align: right;
}

.qualifications__data-right {
  text-align: left;
}

.qualifications__title {
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.qualifications__subtitle {
  display: inline-block;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-0-5);
}

.qualifications__calendar {
  font-size: 0.75rem;
  font-size: var(--smaller-font-size);
  color: hsl(250, 8%, 65%);
  color: var(--text-color-light);
}

.qualifications__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: hsl(250, 69%, 61%);
  background-color: var(--first-color);
  border-radius: 50%;
}

.qualifications__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: hsl(250, 69%, 61%);
  background-color: var(--first-color);
  -webkit-transform: translate(6px, -7px);
          transform: translate(6px, -7px);
}

.qualifications [data-content] {
  display: none;
}

.qualifications__active[data-content] {
  display: block;
}

.qualifications__button.qualifications__active {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}
/*==================== SERVICES ====================*/

.services__container {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.services__content {
  position: relative;
  background-color: #fff;
  background-color: var(--container-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.services__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.services__icon {
  display: block;
  font-size: 1.5rem;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
  margin-bottom: 1rem;
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  margin-bottom: 1rem;
  margin-bottom: var(--mb-1);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.services__button {
  cursor: pointer;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
}

.services__button:hover .button__icon {
  -webkit-transform: translateX(0.25rem);
          transform: translateX(0.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 1rem;
  z-index: 1000;
  z-index: var(--z-modal);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.services__modal-content {
  position: relative;
  background-color: #fff;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.services__modal-service {
  display: -webkit-flex;
  display: flex;
}

.services__modal-title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-1-5);
}
.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
  cursor: pointer;
}
.services__modal-icon {
  margin-bottom: 4px;
  margin-top: 4px;
  height: 16px;
  width: 16px;

  color: hsl(250, 69%, 61%);

  color: var(--first-color);
  margin-right: 0.25rem;
  margin-right: var(--mb-0-25);
}
/* Active Modal */

/*==================== PORTFOLIO ====================*/

.portfolio__container {
  overflow: visible;
  overflow: initial;
}

.portfolio__content {
  padding: 0 1.5rem;
}

.portfolio__img {
  width: 265px;
  border-radius: 0.5rem;
  justify-self: center;
  /* box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}

.portfolio__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-0-5);
}

.portfolio__description {
  margin-bottom: 0.75rem;
  margin-bottom: var(--mb-0-75);
}

.portfolio__button {
  cursor: pointer;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
}

.portfolio__button:hover .button__icon {
  -webkit-transform: translateX(0.25rem);
          transform: translateX(0.25rem);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 19px !important;
  color: hsl(250, 69%, 61%) !important;
  color: var(--first-color) !important;
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}

.swiper-button-prev {
  left: -0.4rem !important;
}
.swiper-button-next {
  right: -0.4rem !important;
}

/* .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -0.5rem !important;
} */

.swiper-pagination-bullet-active {
  background-color: hsl(250, 69%, 61%) !important;
  background-color: var(--first-color) !important;
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullet {
  outline: none !important;
}

.portfolio__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 1rem;
  z-index: 1000;
  z-index: var(--z-modal);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.portfolio__modal-title {
  position: relative;
  background-color: #fff;
  background-color: var(--container-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  color: hsl(250, 69%, 61%);
  color: var(--first-color);

  /* padding: 1.5rem;
  border-radius: 0.5rem; */
}

.portfolio__modal-content {
  position: relative;
  background-color: #fff;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.portfolio__modal-portfolio {
  display: -webkit-flex;
  display: flex;
}

.portfolio__modal-header {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-1-5);
}
.portfolio__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
  cursor: pointer;
}
.portfolio__modal-icon {
  margin-bottom: 4px;
  margin-top: 4px;
  height: 16px;
  width: 16px;

  color: hsl(250, 69%, 61%);

  color: var(--first-color);
  margin-right: 0.25rem;
  margin-right: var(--mb-0-25);
}

/*==================== PROJECT IN MIND ====================*/

.project {
  text-align: center;
}
.project__bg {
  background-color: hsl(250, 69%, 61%);
  background-color: var(--first-color-second);
  padding-top: 3rem;
}

.project__title {
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  margin-bottom: 0.75rem;
  margin-bottom: var(--mb-0-75);
}

.project__description {
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-1-5);
}

.project__title,
.project__description {
  color: #fff;
}

.project__img {
  width: 480px;
  justify-self: center;
}

/*==================== TESTIMONIAL ====================*/

.testimonials__data,
.testimonials__header {
  display: -webkit-flex;
  display: flex;
}

.testimonials__data {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1rem;
  margin-bottom: var(--mb-1);
}

.testimonials__img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 0.75rem;
  margin-right: var(--mb-0-75);
}

.testimonials__name {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.testimonials__client {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(250, 8%, 65%);
  color: var(--text-color-light);
}

.testimonials__description {
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}

.testimonials__icon-star {
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
}

.mySwiper {
  padding-bottom: 40px !important;
  margin-bottom: 40px !important;
}

/*==================== CONTACT ME ====================*/
.contact__container {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.contact__information {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-2);
}

.contact__icon {
  font-size: 2rem;
  color: hsl(250, 69%, 61%);
  color: var(--first-color);
  margin-right: 0.75rem;
  margin-right: var(--mb-0-75);
}

.contact__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.contact__subtitle {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(250, 8%, 65%);
  color: var(--text-color-light);
}

.contact__content {
  background-color: hsl(250, 70%, 96%);
  background-color: var(--input-color);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 0.25rem;
}

.contact__label {
  font-size: 0.75rem;
  font-size: var(--smaller-font-size);
  color: hsl(250, 8%, 15%);
  color: var(--title-color);
}

.contact__input {
  width: 100%;
  background-color: hsl(250, 70%, 96%);
  background-color: var(--input-color);
  color: hsl(250, 8%, 45%);
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem 0.5rem 0;
}
/*==================== FOOTER ====================*/
.footer {
  padding-top: 2rem;
}
.footer__container {
  grid-row-gap: 3.5rem;
  row-gap: 3.5rem;
}

.footer__bg {
  background-color: hsl(250, 69%, 61%);
  background-color: var(--first-color-second);
  padding: 2rem 0 3rem;
}

.footer__title {
  font-size: var(--medium-font-size);

  margin-bottom: 0.25rem;

  margin-bottom: var(--mb-0-25);
}

.footer__subtitle {
  font-size: 0.75rem;
  font-size: var(--smaller-font-size);
}

.footer__links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.footer__link:hover {
  color: hsl(250, 92%, 85%);
  color: var(--first-color-lighter);
}

.footer__social {
  font-size: 1.25rem;
  margin-right: 1.5rem;
  margin-right: var(--mb-1-5);
}

.footer__social:hover {
  color: hsl(250, 92%, 85%);
  color: var(--first-color-lighter);
}

.footer__copy {
  font-size: 0.75rem;
  font-size: var(--smaller-font-size);
  text-align: center;
  color: hsl(250, 8%, 65%);
  color: var(--text-color-light);
  margin-top: 3rem;
  margin-top: var(--mb-3);
}

.footer__title,
.footer__subtitle,
.footer__link,
.footer__social {
  color: #fff;
}
/*========== SCROLL UP ==========*/

.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20rem;
  background-color: hsl(250, 69%, 61%);
  background-color: var(--first-color);
  opacity: 0.8;
  padding: 0 0.3rem;
  border-radius: 0.4rem;
  z-index: 10;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup:hover {
  background-color: hsl(250, 57%, 53%);
  background-color: var(--first-color-alt);
}

.scrollup__icon {
  font-size: 1.5rem;
  color: #fff;
}

/* Show scroll */
.show-scroll {
  bottom: 5rem;
}

/*========== SCROLL BAR ==========*/

::-webkit-scrollbar {
  width: 0.6rem;
  background-color: hsl(250, 12%, 90%);
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(250, 12%, 80%);
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(250, 8%, 65%);
  background-color: var(--text-color-light);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .container {
    margin-left: 1rem;
    margin-left: var(--mb-1);
    margin-right: 1rem;
    margin-right: var(--mb-1);
  }
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav__list {
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }
  .home__content {
    grid-template-columns: 0.25fr, 3fr;
  }
  .home__blob {
    width: 180px;
  }

  .skills__title {
    font-size: 0.938rem;
    font-size: var(--normal-font-size);
  }
  .qualifications__data {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .services__container {
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .services__content {
    padding-right: 3.5rem;
  }
  .services__modal {
    padding: 0 0.5rem;
  }
  .home__modal {
    padding: 0 0.5rem;
  }
  .portfolio__modal {
    padding: 0 0.5rem;
  }
  .project__img {
    width: 200px;
  }
  .testimonials__data,
  .testimonials__header {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .testimonials__img {
    margin-right: 0;
    margin-bottom: 0.25rem;
    margin-bottom: var(--mb-0-25);
  }
  .testimonials__data,
  .testimonials__description {
    text-align: center;
  }
}
/* For medium devices */

@media screen and (min-width: 568px) {
  .home__content {
    grid-template-columns: -webkit-max-content 1fr 1fr;
    grid-template-columns: max-content 1fr 1fr;
  }

  .home__data {
    grid-column: initial;
  }

  .home__img {
    -webkit-order: 1;
            order: 1;
    justify-self: center;
  }

  .about__container,
  .skills__container,
  .portfolio__content,
  .project__container,
  .contact__container,
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .qualifications__sections {
    display: grid;
    grid-template-columns: 0.6fr;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .project__img {
    margin: auto 0 0 0;
  }

  .about__description {
    text-align: left;
    text-align: initial;
  }
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  body {
    margin: 0;
  }
  .section {
    padding: 6rem 0 2rem;
  }
  .section__subtitle {
    margin-bottom: 4rem;
  }
  .header {
    top: 0;
    bottom: auto;
    bottom: initial;
  }

  .header,
  .main,
  .footer__container {
    padding: 0 1rem;
  }

  .nav {
    height: calc(3rem + 1.5rem);
    height: calc(var(--header-height) + 1.5rem);
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }
  .nav__list {
    display: -webkit-flex;
    display: flex;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
  .nav__menu {
    margin-left: auto;
  }
  .change-theme {
    margin: 0;
  }
  .home__container {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .home__content {
    padding-top: 5.5rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
  .home__blob {
    width: 270px;
  }
  .home__scroll {
    display: block;
  }
  .home__scroll-button {
    margin-left: 3rem;
  }

  .about__container {
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .about__img {
    width: 350px;
  }
  .about__description {
    text-align: left;
    text-align: initial;
  }
  .about__info {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .about__button {
    -webkit-justify-content: initial;
            justify-content: initial;
  }
  .qualifications__tabs {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .qualifications__button {
    margin: 0 1rem;
    margin: 0 var(--mb-1);
  }
  .qualifications__section {
    grid-template-columns: 0.5fr;
  }
  .services__container {
    grid-template-columns: repeat(3, 218px);
    -webkit-justify-content: center;
            justify-content: center;
  }
  .services__icon {
    font-size: 2rem;
  }
  .services__content {
    padding: 6rem 0 2rem 2.5rem;
  }
  .services__modal-content {
    width: 450px;
  }
  /* .portfolio__modal-content {
    width: 450px;
  } */

  .portfolio__img {
    width: 400px;
    border-radius: 0.5rem;
    justify-self: center;
  }

  .portfolio__content {
    -webkit-align-items: top;
            align-items: top;
  }
  .project {
    text-align: left;
    text-align: initial;
  }
  .project__bg {
    background: none;
  }
  .project__container {
    background-color: hsl(250, 69%, 61%);
    background-color: var(--first-color-second);
    border-radius: 1rem;
    padding: 3rem 2.5rem 0;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }
  .project__data {
    padding-top: 0.8rem;
  }

  .footer__container {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer__bg {
    padding: 3rem 0 3.5rem;
  }
  .footer__links {
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
  }
  .footer__socials {
    justify-self: flex-end;
  }
  .footer__copy {
    margin-top: 4.5rem;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .header,
  .main,
  .footer__container {
    padding: 0;
  }
  .home__blob {
    width: 320px;
  }
  .home__social {
    -webkit-transform: translateX(-6rem);
            transform: translateX(-6rem);
  }

  .services__container {
    grid-template-columns: repeat(3, 238px);
  }

  /* .portfolio__content {
    column-gap: 0.5rem;
  } */

  .portfolio__img {
    width: 500px;
    border-radius: 0.5rem;
    justify-self: center;
  }

  /* .portfolio__content {
    align-items: top;
  } */

  /* .swiper-button-next::after {
    font-size: 26px !important;
  }

  .swiper-button-prev::after {
    font-size: 26px !important;
  }

  .swiper-button-next {
    right: -0.4rem !important;
    top: 6rem !important;
  }

  .swiper-button-prev {
    left: -0.4rem !important;
    top: 6rem !important;
  } */

  .mySwiper {
    padding-bottom: 80px !important;
  }

  .contact__form {
    width: 460px;
  }

  .contact__inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}
.active-modal {
  opacity: 1;
  visibility: visible;
}

